import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { getToken, getUser } from '../pages/components/localStorageService'
import { verify } from '../redux/features/authThunk'

export const PrivateRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch()

    useEffect(() => {
        const token = getToken();
        if (token === 'undefined' || token === undefined || token === null || !token) {
            setIsLoading(false)
            return;
        }
        const user = getUser();
        dispatch(verify({ user, token })).then((data) => {
            setIsAuthenticated(data.payload.verified)
        }).catch((error) => {
            setIsAuthenticated(false);
        }).finally(() => setIsLoading(false))
        // eslint-disable-next-line
    }, [])

    if (isLoading) {
        return <div>Loading...</div>
    }
    return isAuthenticated ? <Outlet /> : <Navigate to='/' />;
}
