import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CustomAvatar } from "../../controls/CustomAvatar";
import { CustomButton } from "../../controls/CustomButton";
import { CustomTextField } from "../../controls/CustomTextField";
import "./login.css";
import ErrorIcon from "@mui/icons-material/Error";
import messages from "../../user-messages/login.json";
import { validateEmail, validatePassword } from "../../pages/components/validation";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom"
import { setUserSession } from "../../pages/components/localStorageService";
import { login } from "../../redux/features/authThunk";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showEmailError, setShowEmailError] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [btnClicked, setBtnClicked] = useState(false);
    const [userLoginErrorMsg, setUserLoginErrorMsg] = useState('');
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const paperStyle = {
        margin: "135px auto",
        borderRadius: "10px",
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = () => {
        validateEmail(email, setShowEmailError, setIsValidEmail);
        validatePassword(password, setShowPasswordError, setIsValidPassword);
        setBtnClicked(true);
    };

    useEffect(() => {
        if (btnClicked && isValidEmail && isValidPassword) {
            setIsLoadingForm(true)
            dispatch(login({email, password})).then((data) => {
                if (data.error) {
                    setUserLoginErrorMsg(data.payload);
                } else {
                    setUserSession(data.payload.userInfo, data.payload.token)
                    navigate("/home");
                }
            }).catch((error) => {
                setUserLoginErrorMsg(messages.serverError)
            }).finally(() => {
                setIsLoadingForm(false)
            })
        }
        setBtnClicked(false);
        // eslint-disable-next-line
    }, [btnClicked]);

    if (isLoadingForm) {
        return <div>Loading...</div>
    } else {
        return (
            <Paper
                elevation={5}
                style={paperStyle}
                sx={{
                    width: { xs: "370px", sm: "280px" },
                    padding: { xs: "77px 20px 33px", sm: "77px 60px 33px" },
                }}
            >
                <Stack spacing={4} className={"stack-container"}>
                    <h1>{messages.welcome}</h1>
                    <CustomAvatar> A</CustomAvatar>
                </Stack>
                <Stack spacing={5} mt={4}>
                    <div className="input-container">
                        <CustomTextField
                            label={messages.email}
                            variant="standard"
                            onClick={(event) => {
                                setShowEmailError(false);
                            }}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        {showEmailError ? (
                            <div className="error-container">
                                <Typography>{messages.validEmail}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                    <div className="input-container">
                        <CustomTextField
                            label={messages.password}
                            type={showPassword ? messages.text : messages.password}
                            variant="standard"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onClick={(event) => setShowPasswordError(false)}
                            InputProps={{
                                endAdornment: !showPasswordError ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                        {showPasswordError ? (
                            <div className="error-container">
                                <Typography>{messages.enterPassword}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                </Stack>
                <div className="input-container" style={{ marginTop: '15px' }}>
                    <CustomButton
                        size="large"
                        variant="contained"
                        style={{ marginTop: "60px" }}
                        onClick={handleSubmit}
                    >
                        {messages.login}
                    </CustomButton>
                    {userLoginErrorMsg ? (
                        <div className="error-container">
                            <Typography>{userLoginErrorMsg}</Typography>
                            <ErrorIcon color="error" fontSize="small" />
                        </div>
                    ) : null}
                </div>
                <div className="context-container">
                    <Typography className="context" fontSize={15}>
                        {messages.dontHaveAnAccount}
                        <Link to="/signup" className="link">{` ${messages.signUp}`}</Link>
                    </Typography>
                </div>
            </Paper>
        );
    }
};
