import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CustomTextField } from './CustomTextField';

export const CustomDatePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        className="custom-date-picker"
        {...props}
        style={{
          '& .MuiInputBase-root.Mui-error': {
            borderBottomColor: 'initial',
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'initial',
          },
        }}
        renderInput={(params) => <CustomTextField
          variant='standard'
          {...params}
        />}
      />
    </LocalizationProvider>
  );
}

