import { createSlice } from "@reduxjs/toolkit";
import { login, signup, verify } from "./authThunk";

const initialState = {
    user: null,
    isLoading: false,
    error: null
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder
        .addCase(signup.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
            state.user = null
        })
        .addCase(signup.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;
            state.user = action.payload;
        })
        .addCase(signup.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.payload;
            state.user = null
        })
        .addCase(login.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
            state.user = null
        })
        .addCase(login.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;
            state.user = action.payload;
        })
        .addCase(login.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.payload;
            state.user = null
        })
        .addCase(verify.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
            state.user = null;
        })
        .addCase(verify.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;
            state.user = action.payload;
        })
        .addCase(verify.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.payload;
            state.user = null
        });
    }
});

export const isLoading = (state)=> state.isLoading
export default authSlice.reducer;