import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import messages from '../../user-messages/home.json'

export const CheckInOutButtons = ({ isSmallScreen, handleCheckIn, handleCheckOut, isUserCheckedIn }) => {
    return (
        <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={4}>
            <Button variant='contained'
                size='large'
                onClick={handleCheckIn}>
                {messages.checkIn}
            </Button>
            <Button variant='outlined'
                size='large'
                onClick={handleCheckOut}
                disabled={!isUserCheckedIn}>
                {messages.checkOut}
            </Button>
        </Stack>
    );
};
