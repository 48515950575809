export const getUser = () => {
    const user = localStorage.getItem('user');
    if (user === 'undefined' || !user) {
        return null;
    } else {
        return JSON.parse(user);
    }
}
export const getToken = () => {
    return localStorage.getItem('token');
}
export const setUserSession = (user, token) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
}
export const resetUserSession = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}