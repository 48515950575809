import { Typography } from "@mui/material"
import { Stack } from "@mui/system"
import messages from '../../user-messages/logout.json'

export const CheckInOutDetails = () => {
    return (
        <Stack spacing={1}>
            <Typography><span>{messages.checkInLocation}:</span>
                {` ${messages.userLocation}`}
            </Typography>
            <Typography><span>{messages.checkInTime}:</span>
                {` ${messages.checkInTimeVal}`}
            </Typography>
            <Typography><span>{messages.checkOutLocation}:</span>
                {` ${messages.userLocation}`}
            </Typography>
            <Typography><span>{messages.checkoutTime}:</span>
                {` ${messages.checkOutTimeVal}`}
            </Typography>
        </Stack>
    )
}
