import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/system'
import React from 'react'
import { CustomNavbar } from '../../controls/CustomNavbar'
import '../home-page/home.css'
import messages from '../../user-messages/logout.json'
import { Button } from '@mui/material'
import { resetUserSession } from '../../pages/components/localStorageService'
import { CheckInOutDetails } from '../../controls/logout/CheckInOutDetails'

export const Logout = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        resetUserSession();
        navigate('/')
    }
    
    return (
        <div>
            <CustomNavbar />
            <Stack className='main-container' spacing={10}>
                <CheckInOutDetails />
                <Button variant='contained' size='large' onClick={handleLogout}>{messages.logout}</Button>
            </Stack>
        </div>
    )
}
