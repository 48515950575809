import { IconButton, InputAdornment, Paper, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomAvatar } from '../../controls/CustomAvatar';
import { CustomTextField } from '../../controls/CustomTextField';
import messages from "../../user-messages/signup.json";
import './signup.css'
import '../login-page/login.css'
import dayjs from 'dayjs';
import { CustomButton } from '../../controls/CustomButton';
import { CustomDatePicker } from '../../controls/CustomDatePicker';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ErrorIcon from "@mui/icons-material/Error";
import { MOBILE_REGEX, validateAddress, validateConfirmPassword, validateDob, validateEmail, validateMobileNum, validateName, validatePassword } from '../../pages/components/validation';
import { Link, useNavigate } from "react-router-dom"
import formValues from '../../pages/components/formValues';
import { useDispatch } from 'react-redux';
import { signup } from '../../redux/features/authThunk';

export const Signup = () => {
    const [fname, setFname] = useState("");
    const [showFnameError, setShowFnameError] = useState(false);
    const [isValidFname, setIsValidFname] = useState(false);
    const [lname, setLname] = useState("");
    const [showLnameError, setShowLnameError] = useState(false);
    const [isValidLname, setIsValidLname] = useState(false);
    const [email, setEmail] = useState("");
    const [showEmailError, setShowEmailError] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [mobileNum, setMobileNum] = useState('');
    const [showMobileNumError, setShowMobileNumError] = useState(false);
    const [isValidMobileNum, setIsValidMobileNum] = useState(false);
    const [dob, setDob] = useState(dayjs('2000-01-01'));
    const [showDobError, setShowDobError] = useState(false);
    const [isValidDob, setIsValidDob] = useState(true);
    const [country, setCountry] = useState('');
    const [showCountryError, setShowCountryError] = useState(false);
    const [isValidCountry, setIsValidCountry] = useState(false);
    const [state, setState] = useState('');
    const [showStateError, setShowStateError] = useState(false);
    const [isValidState, setIsValidState] = useState(false);
    const [city, setCity] = useState('');
    const [showCityError, setShowCityError] = useState(false);
    const [isValidCity, setIsValidCity] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordError, setShowNewPasswordError] = useState(false);
    const [isValidNewPassword, setIsValidNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [isValidConfirmPassword, setIsValidCounfirmPassword] = useState(false);
    const [isBtnClicked, setIsBtnClicked] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [isValidForm, setIsValidForm] = useState(false);
    const [userSignupErrorMsg, setUserSignupErrorMsg] = useState('');
    const [formVal, setFormVal] = useState(formValues);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const paperStyle = {
        margin: "50px auto",
        borderRadius: "10px",
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMobileNumChange = (event) => {
        const input = event.target.value;
        const digitsOnly = input.replace(MOBILE_REGEX, '');
        setMobileNum(digitsOnly);
    };

    const validateForm = () => {
        setFormVal({
            fname,
            lname,
            email,
            mobileNum,
            dob: dayjs(dob).format('DD/MM/YYYY'),
            country,
            state,
            city,
            password: newPassword
        });
        return (
            isValidFname &&
            isValidLname &&
            isValidEmail &&
            isValidMobileNum &&
            isValidDob &&
            isValidCountry &&
            isValidState &&
            isValidCity &&
            isValidNewPassword &&
            isValidConfirmPassword
        );
    }

    const handleSubmit = () => {
        setUserSignupErrorMsg("");
        validateName(fname, setShowFnameError, setIsValidFname);
        validateName(lname, setShowLnameError, setIsValidLname);
        validateEmail(email, setShowEmailError, setIsValidEmail);
        validateMobileNum(mobileNum, setShowMobileNumError, setIsValidMobileNum);
        validateDob(dob, setShowDobError, setIsValidDob);
        validateAddress(country, state, city, setShowCountryError, setIsValidCountry, setShowStateError, setIsValidState, setShowCityError, setIsValidCity);
        validatePassword(newPassword, setShowNewPasswordError, setIsValidNewPassword);
        validateConfirmPassword(confirmPassword, newPassword, setShowConfirmPasswordError, setIsValidCounfirmPassword, setPasswordMatchError);
        setIsBtnClicked(true);
    }

    useEffect(() => {
        if (isBtnClicked) {
            setIsValidForm(validateForm());
            setIsBtnClicked(false);
        }// eslint-disable-next-line
    }, [isBtnClicked]);

    useEffect(() => {
        if (isValidForm) {
            setIsLoadingForm(true);
            dispatch(signup(formVal)).then((data) => {
                if (data.error) {
                    setUserSignupErrorMsg(data.payload)
                } else {
                    navigate("/");
                }
            }).catch((error) => {
                setUserSignupErrorMsg(messages.serverError)
            }).finally(() => {
                setIsLoadingForm(false);
                setIsValidForm(false);
            });
        }// eslint-disable-next-line
    }, [isValidForm]);

    if (isLoadingForm) {
        return <div>Loading...</div>
    } else {
        return (
            <Paper
                elevation={5}
                style={paperStyle}
                sx={{
                    width: { xs: "400px", sm: "450px" },
                    padding: { xs: "77px 20px 33px", sm: "50px 60px 33px" },
                }}
            >
                <Stack spacing={4} className={"stack-container"}>
                    <h1>{messages.register}</h1>
                    <CustomAvatar> A</CustomAvatar>
                </Stack>
                <Stack spacing={3} mt={4}>
                    <Stack direction={isSmallScreen ? "column" : "row"} spacing={isSmallScreen ? 3 : 12}>
                        <div className="input-container">
                            <CustomTextField
                                label={showFnameError && !isSmallScreen ? "" : messages.fName}
                                variant="standard"
                                value={fname}
                                sx={showFnameError && !isSmallScreen ? { mt: 1.7 } : null}
                                onChange={(event) => setFname(event.target.value)}
                                onClick={(event) => setShowFnameError(false)}
                            />
                            {showFnameError ? (
                                <div onClick={(event) => setShowFnameError(false)}
                                    className="error-container">
                                    <Typography>{messages.enterFname}</Typography>
                                    <ErrorIcon color="error" fontSize="small" />
                                </div>
                            ) : null}
                        </div>
                        <div className="input-container">
                            <CustomTextField
                                label={showLnameError && !isSmallScreen ? "" : messages.lName}
                                variant="standard"
                                value={lname}
                                sx={showLnameError && !isSmallScreen ? { mt: 1.7 } : null}
                                onChange={(event) => setLname(event.target.value)}
                                onClick={(event) => setShowLnameError(false)}
                            />
                            {showLnameError ? (
                                <div onClick={(event) => setShowLnameError(false)} className="error-container">
                                    <Typography>{messages.enterLname}</Typography>
                                    <ErrorIcon color="error" fontSize="small" />
                                </div>
                            ) : null}
                        </div>
                    </Stack>
                    <div className="input-container">
                        <CustomTextField
                            label={messages.email}
                            variant="standard"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            onClick={(event) => setShowEmailError(false)}
                        />
                        {showEmailError ? (
                            <div className="error-container">
                                <Typography>{messages.validEmail}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                    <div className="input-container">
                        <CustomTextField
                            label={messages.mobileNum}
                            variant="standard"
                            type={'tel'}
                            value={mobileNum}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                maxLength: 10
                            }}
                            onChange={handleMobileNumChange}
                            onClick={(event) => setShowMobileNumError(false)}
                        />
                        {showMobileNumError ? (
                            <div className="error-container">
                                <Typography>{messages.validMobileNum}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                    <div className="input-container">
                        <CustomDatePicker
                            label={messages.dob}
                            inputFormat="DD/MM/YYYY"
                            value={dob}
                            onChange={(newDate) => setDob(newDate)}
                            onClick={(event) => setShowDobError(false)}
                        />
                        {showDobError ? (
                            <div className="error-container"
                                style={{ marginRight: 25 }}
                            >
                                <Typography>{messages.enterValidDob}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                    <div className="input-container">
                        <CustomTextField
                            label={messages.country}
                            variant="standard"
                            value={country}
                            onChange={(event) => setCountry(event.target.value)}
                            onClick={(event) => setShowCountryError(false)}
                        />
                        {showCountryError ? (
                            <div className="error-container">
                                <Typography>{messages.enterCountry}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                    <Stack direction={isSmallScreen ? "column" : "row"} spacing={isSmallScreen ? 3 : 5}>
                        <div className="input-container">
                            <CustomTextField
                                label={messages.state}
                                variant="standard"
                                value={state}
                                onChange={(event) => setState(event.target.value)}
                                onClick={(event) => setShowStateError(false)}
                            />
                            {showStateError ? (
                                <div className="error-container">
                                    <Typography>{messages.enterState}</Typography>
                                    <ErrorIcon color="error" fontSize="small" />
                                </div>
                            ) : null}
                        </div>
                        <div className="input-container">
                            <CustomTextField
                                label={messages.city}
                                variant="standard"
                                value={city}
                                onChange={(event) => setCity(event.target.value)}
                                onClick={(event) => setShowCityError(false)}
                            />
                            {showCityError ? (
                                <div className="error-container">
                                    <Typography>{messages.enterCity}</Typography>
                                    <ErrorIcon color="error" fontSize="small" />
                                </div>
                            ) : null}
                        </div>
                    </Stack>
                    <div className="input-container">
                        <CustomTextField
                            label={messages.newPassword}
                            variant="standard"
                            value={newPassword}
                            onChange={(event) => setNewPassword(event.target.value)}
                            type={showNewPassword ? messages.text : messages.password}
                            onClick={(event) => setShowNewPasswordError(false)}
                            InputProps={{
                                endAdornment: !showNewPasswordError ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ) : null
                            }}
                        />
                        {showNewPasswordError ? (
                            <div className="error-container">
                                <Typography>{messages.enterNewPassword}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                    <div className="input-container">
                        <CustomTextField
                            label={messages.confirmPassword}
                            variant="standard"
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            type={showConfirmPassword ? messages.text : messages.password}
                            onClick={(event) => {
                                setShowConfirmPasswordError(false)
                                setPasswordMatchError(false)
                            }}
                            InputProps={{
                                endAdornment: !showConfirmPasswordError && !passwordMatchError ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ) : null
                            }}
                        />
                        {showConfirmPasswordError ? (
                            <div className="error-container">
                                <Typography>{messages.enterConfirmPassword}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                        {passwordMatchError ? (
                            <div className="error-container">
                                <Typography>{messages.passwordMatchError}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                    <div className="input-container">
                        <CustomButton
                            size="large"
                            variant="contained"
                            style={{ marginTop: "70px" }}
                            onClick={handleSubmit}
                        >
                            {messages.signUp}
                        </CustomButton>
                        {userSignupErrorMsg ? (
                            <div onClick={(event) => setShowLnameError(false)} className="error-container">
                                <Typography>{userSignupErrorMsg}</Typography>
                                <ErrorIcon color="error" fontSize="small" />
                            </div>
                        ) : null}
                    </div>
                </Stack>
                <div className="reg-context-container">
                    <Typography className="reg-context">
                        {messages.alreadyHaveAnAcount}
                        <Link to='/' className='link'>{` ${messages.login}`}</Link>
                    </Typography>
                </div>
            </Paper>
        )
    }
}
