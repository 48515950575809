import { Routes, Route } from 'react-router-dom';
import { HomePage } from './components/home-page/Home';
import { Login } from './components/login-page/Login';
import { Logout } from './components/logout-page/Logout';
import { Signup } from './components/signup-page/Signup';
import { PrivateRoute } from './routes/PrivateRoute';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route element={<PrivateRoute />}>
        <Route path='/home' element={<HomePage />} />
        <Route path='/logout' element={<Logout />} />
      </Route>
    </Routes>
  );
}

export default App;
