import dayjs from 'dayjs';
const formValues = {
    fname: '',
    lname: '',
    email: '',
    mobileNum: '',
    dob: dayjs('2000-01-01'),
    country: '',
    state: '',
    city: '',
    password: ''
  };
  
export default formValues;
  